import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../pages/main/main.css';

const Main = () => {
  const navigate = useNavigate();
  const [activeTabs, setActiveTabs] = useState(new Set(['dashboard']));
  const [activeTab, setActiveTab] = useState('dashboard');

  const menuItems = [
    { name: 'Dashboard', tab: 'dashboard' },
    { name: 'Producto', tab: 'producto' },
    { name: 'Categoría', tab: 'categoria' },
    { name: 'Factura', tab: 'factura' },
  ];

  const handleMenuClick = (tab) => {
    if (!activeTabs.has(tab)) {
      setActiveTabs(new Set([...activeTabs, tab]));
    }
    setActiveTab(tab);
  };

  const closeTab = (tab) => {
    const newActiveTabs = new Set(activeTabs);
    newActiveTabs.delete(tab);
    setActiveTabs(newActiveTabs);
    if (activeTab === tab) {
      setActiveTab(newActiveTabs.size > 0 ? Array.from(newActiveTabs)[0] : '');
    }
  };

  const handleLogout = () => {
    navigate('/login');
  };

  return (
    <div className="main-container">
      <header>
        <h1>My Stock</h1>
      </header>
      <main>
        <div className="menu">
          <div className="menu-main">
            {menuItems.map((item) => (
              <button
                key={item.tab}
                className="menu-button"
                onClick={() => handleMenuClick(item.tab)}
              >
                {item.name}
              </button>
            ))}
          </div>
          <div className="menu-footer">
            <button className="menu-footer-button" onClick={handleLogout}>
              Salir
            </button>
          </div>
        </div>
        <div className="wrapper">
          <div className="menu-tabs">
            {Array.from(activeTabs).map((tab) => (
              <button
                key={tab}
                className={`tab-button ${activeTab === tab ? 'active' : ''}`}
                onClick={() => setActiveTab(tab)}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
                <span className="close-tab" onClick={() => closeTab(tab)}>
                  ×
                </span>
              </button>
            ))}
          </div>
          <div className="tab">
            {Array.from(activeTabs).map((tab) => (
              <div
                key={tab}
                className={`tab-content ${activeTab === tab ? 'active' : ''}`}
              >
                Contenido de {tab}
              </div>
            ))}
          </div>
        </div>
      </main>
      <footer>Footer</footer>
    </div>
  );
};

export default Main;