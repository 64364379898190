import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../pages/login/login.css';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [environment, setEnvironment] = useState('production');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí puedes agregar lógica de autenticación
    navigate('/main'); // Asumiendo que usas react-router para la navegación
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="logo-container">
        <img src={process.env.PUBLIC_URL + '/img/logo.png'} alt="Sistema de inventario" className="logo" />
        </div>
        <h2>Sistema de Inventario</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="username">Usuario</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Clave</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label htmlFor="environment">Conectar a</label>
            <select
              id="environment"
              value={environment}
              onChange={(e) => setEnvironment(e.target.value)}
            >
              <option value="production">Producción</option>
              <option value="testing">Pruebas</option>
            </select>
          </div>
          <button type="submit">Ingresar</button>
        </form>
      </div>
    </div>
  );
};

export default Login;